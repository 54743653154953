<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
    >
      <v-list-item
        v-for="(itm,i) in items.filter(it => !(it.check && !isAdmin))"
        :key="i"
        :to="itm.url"
        :exact="itm.name === 'Home'"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="itm.name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    computed:{
      ...mapGetters(['isAuth','isAdmin'])
    },
  }
</script>
